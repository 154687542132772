$(document).ready(function() {
  if ($('#season-selector').length > 0) {
    $('#last-season-button').click(function() {
      var start_date_string = $('#contract_start_date').val().trim();
      var end_date_string = $('#contract_end_date').val().trim();
      if (start_date_string.match(/\d{2}\.\d{2}\.\d{4}/) && end_date_string.match(/\d{2}\.\d{2}\.\d{4}/)) {
        var year = parseInt(start_date_string.slice(-4));

        $('#contract_start_date').val('01.05.' + (year - 1));
        $('#contract_end_date').val('30.04.' + year);
      }
    });

    $('#current-season-button').click(function() {
      var start_date_string = $('#contract_start_date').val().trim();
      var end_date_string = $('#contract_end_date').val().trim();
      if (start_date_string.match(/\d{2}\.\d{2}\.\d{4}/) && end_date_string.match(/\d{2}\.\d{2}\.\d{4}/)) {
        var current_year = (new Date).getFullYear();

        $('#contract_start_date').val('01.05.' + current_year);
        $('#contract_end_date').val('30.04.' + (current_year + 1));
      }
    });

    $('#next-season-button').click(function() {
      var start_date_string = $('#contract_start_date').val().trim();
      var end_date_string = $('#contract_end_date').val().trim();
      if (start_date_string.match(/\d{2}\.\d{2}\.\d{4}/) && end_date_string.match(/\d{2}\.\d{2}\.\d{4}/)) {
        var year = parseInt(end_date_string.slice(-4));

        $('#contract_start_date').val('01.05.' + year);
        $('#contract_end_date').val('30.04.' + (year + 1));
      }
    });
  }
});
