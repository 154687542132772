$(document).ready(function() {
  $("#dttb").on("click", ".clickable-row", function(event) {
    if ($(event.target).hasClass('btn') || $(event.target).hasClass('fa')) {
      return
    }else{
      window.location = $(this).data("href");
    }
  });
});

$(document).ready(function() {
  $("#clickable-contacts, #clickable-contracts, #clickable-extra-marketing-activities").on("click", ".clickable-row", function(event) {
    window.location = $(this).data("href");
  });
});
