$(document).ready( function(){
  $(".global-search input").focus(function(){
    $(".search-results").css("display", "block")
  });
  $(".global-search input").on( "focusout", function(){
    setTimeout(function(){
      $(".search-results").css("display", "none")
    }, 500);
  });
  $('.global-search input').on('input', function() {
    var x = $('.global-search input').val().replace(/\s/g,"%20");
    setTimeout(function(){
      y = $('.global-search input').val().replace(/\s/g,"%20");
      if(x === y){
        $('.search-results').load('/search?search_params='+y)
      }
    }, 1000);
  });
})
