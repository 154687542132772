$(document).ready( function () {
  $('#contracts-tab a, #contracts-tab .dropdown-item').on('click', function (e) {
    e.preventDefault();
    $(this).tab('show');
    $('#contract-selected').text('Saison auswählen');
  });

  $('#contracts-tab .dropdown-menu a').click(function(){
    $('#contract-selected').text($(this).text());
  });
});
