$(document).ready(function(){
  $('.datepicker').datepicker({
    format: 'dd.mm.yyyy',
    defaultDate: $('#workout_start_at').val(),
    locale: 'de',
    language: 'de',
    todayBtn: true,
    todayHighlight: true,
  });
});
