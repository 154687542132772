$(document).ready(function() {
  $("#editable_customer_status").removeClass("is-valid")
  $('.info-customer-status').hide();
  $('#editable_customer_status').blur(function () {
    var formData = $('#editable_customer_status').val()
    $.ajax({
      url: location.href + "/update_customer_status",
      method: 'PUT',
      data: { customer: {customer_status_id: formData } },
      dataType: 'html'
    }).done(function(response){
      $('.info-customer-status').fadeIn().delay(4000).fadeOut();
    });
  });
});
