import Vue from 'vue'

panda.initMoneyBot = (el, options) => {
  window.moneyBot = new Vue({
    el,
    data: {
      discount_type: options.discount_type,
      discount_percentage: options.discount_percentage,
      discount_absolute: options.discount_absolute,
      discount_final: options.discount_final,
      marketing_category_list_price_list: {}
    },
    computed: {
      listing_total: function() {
        var sum = 0
        for (const key in this.marketing_category_list_price_list) {
          if (this.marketing_category_list_price_list[key]['listing'] != '') {
            sum += parseFloat(this.marketing_category_list_price_list[key]['listing'])
          };
        }
        return sum
      },
      real_total: function() {
        var sum = 0
        for (const key in this.marketing_category_list_price_list) {
          if (this.marketing_category_list_price_list[key]['real'] != '') {
            sum += parseFloat(this.marketing_category_list_price_list[key]['real'])
          };
        }
        return sum
      }
    },
    methods: {
      listingTotal: function() {
        var sum = 0
        for (const key in this.marketing_category_list_price_list) {
          if (this.marketing_category_list_price_list[key]['listing'] != '') {
            sum += parseFloat(this.marketing_category_list_price_list[key]['listing'])
          };
        }
        return sum
      },
      realTotal: function() {
        var sum = 0
        for (const key in this.marketing_category_list_price_list) {
          if (this.marketing_category_list_price_list[key]['real'] != ''){
            sum += parseFloat(this.marketing_category_list_price_list[key]['real'])
          }
        };
        return sum
      },
      catRemoved() {
        var that = this;
        setTimeout(function() {
          that.updateList()
        }, 200);
      },
      monetize(sum) {
        return sum.toLocaleString('de', {style: 'currency', currency: 'EUR'})
      },
      updateList() {
        var x = {}
        $('.marketing_category_list_price').each(function() {
          x[$(this).attr('id')] = {listing: $(this).val()}
        });
        this.marketing_category_list_price_list = x
      },
      patchSame(){
        for (const key in this.marketing_category_list_price_list) {
          this.marketing_category_list_price_list[key]['real'] = this.marketing_category_list_price_list[key]['listing']
        };
        this.setRealPrice()
      },
      patchPercentage(discount) {
        if (discount == 0){
          this.patchSame()
        }else{
          for (const key in this.marketing_category_list_price_list) {
            this.marketing_category_list_price_list[key]['real'] = this.marketing_category_list_price_list[key]['listing'] -this.marketing_category_list_price_list[key]['listing']*(discount/100)
          };
        }
        this.discount_absolute = (this.listingTotal() - this.realTotal()).toFixed(2)
        this.discount_final = (this.realTotal()).toFixed(2)
        this.setRealPrice()
      },
      priceWeight(key) {
        return this.marketing_category_list_price_list[key]['listing']/this.listing_total
      },
      patchAbsolute(discount, from) {
        if (discount == 0){
          this.patchSame()
        }else{
          for (const key in this.marketing_category_list_price_list) {
            this.marketing_category_list_price_list[key]['real'] = this.marketing_category_list_price_list[key]['listing']-(discount*this.priceWeight(key))
          };
        }
        this.discount_percentage = parseFloat((this.listingTotal() - this.realTotal())*100/this.listingTotal()).toFixed(2)
        if (from == 'final') {
          this.discount_absolute = (this.listingTotal() - this.realTotal()).toFixed(2)
        }else{
          this.discount_final = (this.realTotal()).toFixed(2)
        }
        this.setRealPrice()
      },
      setRealPrice(){
        for (const key in this.marketing_category_list_price_list) {
          $('#'+ key.replace('list_price', 'real_price')).val(parseFloat(this.marketing_category_list_price_list[key]['real']).toFixed(2))
        };
      },
      parseDiscount(discount){
        if (discount == ''){
          return 0
        }else{
          return parseFloat(discount.toString().replace(',', '.'))
        }
      },
      doASwitch() {
        switch(this.discount_type) {
          case 'percentage':
            this.patchPercentage(this.parseDiscount(this.discount_percentage))
            break;
          case 'absolute':
            this.patchAbsolute(this.parseDiscount(this.discount_absolute), 'absolute')
            break;
          case 'final':
            this.patchAbsolute(this.listing_total - this.parseDiscount(this.discount_final), 'final')
            break;
        }
      }
    },
    watch: {
      marketing_category_list_price_list: function() {
        this.doASwitch()
      },
      discount_type: function() {
        this.doASwitch()
      },
      discount_percentage: function(new_value, old_value) {
        if (new_value > 100) {
          this.discount_percentage = 100
        }
        this.doASwitch()
      },
      discount_final: function(new_value, old_value) {
        if (new_value > this.listing_total) {
          this.discount_final = this.listing_total
        }
        this.doASwitch()
      },
      listing_total: function() {
        if (this.discount_final > this.listing_total) {
          this.discount_final = this.listing_total
        }
      },
      discount_absolute: function(new_value, old_value) {
        if (new_value > this.listing_total) {
          this.discount_absolute = this.listing_total
        }
        this.doASwitch()
      }
    },
    mounted: function() {
      this.updateList()
    }
  })
}
