$(document).ready(function() {
  if ($('#contract_auto_extension_no_extension:checked').length > 0) {
    $('#extension_end_date_wrapper').hide();
    $('#extension_seasons_count_wrapper').hide();
  } else if ($('#contract_auto_extension_with_extension:checked').length > 0) {
    $('#extension_end_date_wrapper').show();
    $('#extension_seasons_count_wrapper').hide();
  } else if ($('#contract_auto_extension_follow_up:checked').length > 0) {
    $('#extension_end_date_wrapper').hide();
    $('#extension_seasons_count_wrapper').show();
  }

  $("#contract_auto_extension_no_extension").click(function() {
    $('#extension_end_date_wrapper').hide();
    $('#extension_end_date').val('');
    $('#extension_seasons_count_wrapper').hide();
    $('#extension_seasons_count').val('');
  });

  $("#contract_auto_extension_with_extension").click(function() {
    $('#extension_end_date_wrapper').show();
    $('#extension_seasons_count_wrapper').hide();
    $('#extension_seasons_count').val('');
  });

  $("#contract_auto_extension_follow_up").click(function() {
    $('#extension_end_date_wrapper').hide();
    $('#extension_end_date').val('');
    $('#extension_seasons_count_wrapper').show();
  });
});
