window.dataTableLocales = {
    "sEmptyTable":      "Keine Daten in der Tabelle vorhanden",
    "decimal": ",",
    "thousands": ".",
    "sInfo":            "_START_ bis _END_ von _TOTAL_ Einträgen",
    "sInfoEmpty":       "Keine Daten vorhanden",
    "sInfoFiltered":    "(gefiltert von _MAX_ Einträgen)",
    "sInfoPostFix":     "",
    "sInfoThousands":   ".",
    "sLengthMenu":      "_MENU_ Einträge anzeigen",
    "sLoadingRecords":  "Wird geladen ..",
    "sProcessing":      "Bitte warten ..",
    "sSearch":          "Suchen",
    "sZeroRecords":     "Keine Einträge vorhanden",
    "oPaginate": {
        "sFirst":       "Erste",
        "sPrevious":    "Zurück",
        "sNext":        "Nächste",
        "sLast":        "Letzte"
    },
    "oAria": {
        "sSortAscending":  ": aktivieren, um Spalte aufsteigend zu sortieren",
        "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
    },
    "select": {
        "rows": {
            "_": "%d Zeilen ausgewählt",
            "0": "",
            "1": "1 Zeile ausgewählt"
        }
    },
    "buttons": {
        "print":    "Drucken",
        "colvis":   "Spalten",
        "copy":     "Kopieren",
        "copyTitle":    "In Zwischenablage kopieren",
        "copyKeys": "Taste <i>ctrl</i> oder <i>\u2318</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.",
        "copySuccess": {
            "_": "%d Spalten kopiert",
            "1": "1 Spalte kopiert"
        }
    }
}

$(document).ready( function () {
  if ($('#dttb, #dttb-0, #dttb-1, #dttb-2, #dttb-3, #dttb-4, #dttb-5').length > 0) {
    if ($('.dataTables_filter').length > 0) {
      location.reload();
    }
    $('#dttb, #dttb-0, #dttb-1, #dttb-2, #dttb-3, #dttb-4, #dttb-5').DataTable( {
      "language": dataTableLocales,
      "pageLength": 25,
      "order": [0, 'asc'],
      "footerCallback": function ( row, data, start, end, display ) {
        if ($('tfoot').length > 0){
          var api = this.api(), data;

          // Remove the formatting to get integer data for summation
          var intVal = function ( i ) {
            return typeof i === 'string' ?
              i.replace('€', '').replace(/[\,.]/g, '')*1 :
              typeof i === 'number' ?
                i : 0;
          };

          // Total over all pages
          var total = function(i) {
            return (api
              .column( i )
              .data()
              .reduce( function (a, b) {
                  return intVal(a) + intVal(b);
              }, 0 )/100).toLocaleString('de', {style: 'currency', currency: 'EUR'}).slice(0, -2);
          };
          // Total over this page
          var pageTotal = function(i) {
            return (api
              .column( i, { page: 'current'} )
              .data()
              .reduce( function (a, b) {
                  return intVal(a) + intVal(b);
              }, 0 )/100).toLocaleString('de', {style: 'currency', currency: 'EUR'}).slice(0, -2);
          };
          // Update footer
          for (var i = 1; i < 6; i++) {
            if ( pageTotal(i) == total(i) ) {
              $( api.column( i ).footer() ).html(
                  '€ '+pageTotal(i)
              );
            }else{
              $( api.column( i ).footer() ).html(
                  '€ '+pageTotal(i) +' ( € '+ total(i) +' insgesamt )'
              );
            }
          };
          // total rabatt percentage over all pages
          var page_total_rabatt = function(){
            if (isNaN(intVal(pageTotal(3))*100/intVal(pageTotal(1)))) {
              return '0,00'
            }else{
              return (intVal(pageTotal(3))*100/intVal(pageTotal(1))).toFixed(2).toString()
            }
          }
          // total rabatt percentage over this page
          var total_rabatt = function() {
            if ( isNaN(intVal(total(3))*100/intVal(total(1))) ) {
              return '0,00'
            }else{
              return (intVal(total(3))*100/intVal(total(1))).toFixed(2).toString()
            }
          }
          if ( page_total_rabatt() == total_rabatt() ){
            $( api.column( 4 ).footer() ).html(
              page_total_rabatt() + ' %'
            );
          }else{
            $( api.column( 4 ).footer() ).html(
              page_total_rabatt() + ' % ( ' + total_rabatt() + ' % insgesamt )'
            );
          }
        }
      }
    } );
  }

  $('#convoModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget)
    var recipient = button.data('name')
    var id = button.data('id')
    var modal = $(this)
    modal.find('.modal-title').text('neues Gespräch mit ' + recipient)
    modal.find('#convo_contact_id').val(id)
  });

  $('#showConvoModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget)
    var recipient = button.data('name')
    var body = button.data('body')
    var subject = button.data('subject')
    var modal = $(this)
    modal.find('.modal-title').text('Gespräch mit ' + recipient)
    modal.find('#convo-body').text(body)
    modal.find('#convo-subject').text(subject)
  });
} );
