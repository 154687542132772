$(document).ready(function() {
  $("#editable_miscellaneous").removeClass("is-valid");
  $('.info-customer-misc').hide();
  $('#editable_miscellaneous').blur(function () {
    var formData = $('#editable_miscellaneous').val()
    $.ajax({
      url: location.href + "/update_miscellaneous",
      method: 'PUT',
      data: { customer: {miscellaneous: formData } },
      dataType: 'html'
    }).done(function(response){
      $('.info-customer-misc').fadeIn().delay(4000).fadeOut();
    });
  });
});
