$(function() {
  $('.easy-read').each(function(){
    var limit = $(this).attr('read-limit');
    var str = $(this).html().substring(0, limit);
    var strHidden = $(this).html().substring(limit, $(this).html().length);
    if ($(this).html().length > limit && $(this).html().search('<span') == -1) {
      $(this).html(str+'<span class="hidden-text" hidden>'+strHidden+'</span> <a class="read-more"><br>Weiterlesen</a>');
    }
  });

  $('.easy-read').on('click', 'a.read-more', function() {
    $(this).prev().removeAttr('hidden');
    $(this).html('<br>Lese weniger').attr('class', 'read-less');
  });
  $('.easy-read').on('click', 'a.read-less', function() {
    $(this).prev().attr('hidden', 'true');
    $(this).html('<br>Weiterlesen').attr('class', 'read-more');
  });
});
